import React from 'react'

const View = ({ views, styleV }) => (
  <div className="flex flex-row justify-center mb-4 md:mr-6 items-center">
    <div className={`mr-2 ${styleV}`}>
      ចំនួនអាន៖ <span className="text-indigo-600">{views}</span>
    </div>
  </div>
)

export default View
