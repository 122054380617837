import React from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

const Share = ({ url }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center mb-4 items-center">
      <div className=" mr-2 mb-4 md:mb-0">ចែករំលែក៖ </div>
      <div className="flex">
        <TwitterShareButton
          url={url}
          className="shadow-none h-10 md:h-7 border-none focus:outline-none mr-4 md:mr-1"
        >
          <svg
            className="fill-current text-blue-800 h-10 md:h-5 mr-1"
            style={{ color: 'rgb(100, 174, 249)' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 920 1000"
          >
            <path d="M920 214c-24 36-55.333 68.667-94 98v24c0 86.667-20 170-60 250S664 733.667 580 789s-180.667 83-290 83c-106.667 0-203.333-28-290-84 9.333 1.333 24.667 2 46 2 88 0 166-26.667 234-80-41.333-1.333-78-14-110-38s-54-55.333-66-94c6.667 2.667 18 4 34 4 17.333 0 34-2 50-6-44-9.333-80-31.333-108-66s-42-74.667-42-120v-2c24 13.333 52 21.333 84 24-56-38.667-84-91.333-84-158 0-32 8.667-63.333 26-94 102.667 125.333 232.667 190.667 390 196-4-12-6-26-6-42 0-52 18.333-96.333 55-133s81.667-55 135-55c54.667 0 100 19.333 136 58 40-8 80-22.667 120-44-13.333 44-40.667 78.667-82 104 37.333-5.333 73.333-15.333 108-30" />
          </svg>
        </TwitterShareButton>
        <FacebookShareButton
          url={url}
          className="shadow-none h-10 md:h-7 border-none focus:outline-none"
        >
          <svg
            className="fill-current h-10 md:h-5"
            style={{ color: 'rgb(75, 125,	218)' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 860 1000"
          >
            <path d="M752 80c29.333 0 54.667 10.333 76 31s32 45.667 32 75v646c0 29.333-10.667 54.667-32 76s-46.667 32-76 32H590V630h114V496H590v-70c0-20 9.333-30 28-30h86V244h-96c-49.333 0-90.667 18-124 54s-50 80-50 132v66H330v134h104v310H108c-29.333 0-54.667-10.667-76-32S0 861.333 0 832V186c0-29.333 10.667-54.333 32-75s46.667-31 76-31h644" />
          </svg>
        </FacebookShareButton>
      </div>
    </div>
  )
}

export default Share
