import React from 'react'
import { Link } from 'gatsby'
import kebabCase from 'kebab-case'
import { colorSchemes } from '../../constants'

const Tags = ({ tags = [] }) => {
  return (
    <div className="flex flex-wrap mb-4">
      {tags.map((tag, i) => {
        const colorScheme =
          colorSchemes[Math.floor(Math.random() * colorSchemes.length)]

        return (
          <Link
            className={`border px-1 mr-1 mb-1 shadow-none rounded-sm ${colorScheme.bg} ${colorScheme.bd} text-black hover:text-white`}
            key={i}
            to={`/tags/${kebabCase(tag)}`}
          >
            #{tag}
          </Link>
        )
      })}
    </div>
  )
}

export default Tags
