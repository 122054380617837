import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Disqus } from 'gatsby-plugin-disqus'
import SEO from '../components/seo'
import Layout from '../layouts/layout'
import Writer from '../components/post/writer-bar'
import Tags from '../components/post/tags-bar'
import Share from '../components/post/share'
import View from '../components/post/view'
import { formatViewCount } from '../utils'
import { baseURL } from '../constants'
import '../styles/blog-post.scss'

const BlogPostTemplate = ({ data, location }) => {
  const disqusConfig = {
    url: location.href,
    identifier: data.markdownRemark.fields.slug,
    title: data.markdownRemark.frontmatter.title
  }

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const tags = data.markdownRemark.frontmatter.tags
  const readingTime = Math.ceil(data.markdownRemark.fields.readingTime.minutes)
  const allPageViews = formatViewCount(
    data.allPageViews.nodes.length ? data.allPageViews.nodes[0].totalCount : 0
  )

  let thumbnail = ''
  if (
    post.frontmatter.featuredImage &&
    post.frontmatter.featuredImage.publicURL
  ) {
    thumbnail = baseURL + post.frontmatter.featuredImage.publicURL
  } else {
    const featuredImage = data.allFile.edges.find(
      (edge) => edge.node.base === 'default-cover.png'
    )
    if (featuredImage && featuredImage.node) {
      thumbnail = baseURL + featuredImage.node.publicURL
    }
  }

  useEffect(() => {
    const pre = document.getElementsByTagName('pre')
    const pl = pre.length
    for (let i = 0; i < pl; i++) {
      pre[i].innerHTML =
        '<span class="line-number"></span>' +
        pre[i].innerHTML +
        '<span class="cl"></span>'
      const num = pre[i].innerHTML.split(/\n/).length
      for (let j = 0; j < num; j++) {
        const line_num = pre[i].getElementsByTagName('span')[0]
        line_num.innerHTML += '<span>' + (j + 1) + '</span>'
      }
    }
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt}
        image={thumbnail}
        url={location.href}
      />
      <article
        className="shadow-md pt-8 md:pt-12 px-4 md:px-12 pb-2 mb-1 md:mb-0"
        style={{
          color: 'var(--textNormal)',
          backgroundColor: 'var(--bg-card)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
      >
        <header>
          <h1 className="article-header font-bold text-2xl mb-4 leading-9">
            {post.frontmatter.title}
          </h1>
          <Writer
            date={post.frontmatter.date}
            authorId={post.frontmatter.author}
            readingTime={readingTime}
          />
          <div className="flex flex-col md:flex-row justify-between mb-4">
            <Tags tags={tags} />
            <div className="hidden md:flex">
              <View views={allPageViews} />
              <Share url={location.href} />
            </div>
          </div>
        </header>
        <section
          className="mb-16"
          dangerouslySetInnerHTML={{
            __html: post.html
          }}
        />
        <div
          className="mb-8"
          style={{
            background: 'var(--bgMute)'
          }}
        >
          <div className="flex md:hidden items-center justify-center pt-10 pb-4">
            <View views={allPageViews} />
          </div>
          <div className="flex md:hidden items-center justify-center pb-6">
            <Share url={location.href} />
          </div>
        </div>
        <Disqus
          config={disqusConfig}
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        />
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 525, truncate: true)
      fields {
        readingTime {
          minutes
        }
        slug
      }
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          id
          publicURL
          base
        }
        author
        tags
      }
    }
    allFile(filter: { ext: { ne: ".md" } }) {
      edges {
        node {
          id
          publicURL
          base
        }
      }
    }
    allPageViews(filter: { id: { eq: $slug } }, limit: 1) {
      nodes {
        id
        totalCount
      }
    }
  }
`
