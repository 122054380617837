import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import kebabCase from 'kebab-case'
import { getDateInKhmer } from '../../utils'

const Writer = ({ date, authorId, readingTime }) => {
  const data = useStaticQuery(graphql`
    query WriterQuery {
      avatar: file(absolutePath: { regex: "/davychhouk.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 50, height: 50)
        }
      }
      site {
        siteMetadata {
          authors {
            id
            name
            nameInKhmer
            social {
              facebook
              twitter
              github
            }
            summary
          }
        }
      }
    }
  `)
  const [hovered, setHovered] = useState(false)
  const { authors } = data.site.siteMetadata
  const author = authors.find((author) => author.id === authorId)
  return (
    <div className="flex items-center mt-4 mb-6">
      <Link
        className="shadow-none mr-4"
        style={{
          height: 60,
          width: 60
        }}
        to={`/writers/${kebabCase(author.name)}/1`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <GatsbyImage
          image={getImage(data.avatar)}
          alt={`${author.name}'s profile picture`}
          style={{
            width: 60,
            height: 60,
            borderRadius: 60
          }}
          imgStyle={{
            marginBottom: 0,
            borderRadius: 60
          }}
        />
      </Link>
      <div>
        <p>
          <Link
            className={`shadow-none hover:text-red-400 mr-1 ${
              hovered ? 'text-red-400' : ''
            }`}
            to={`/writers/${kebabCase(author.name)}/1`}
          >
            <strong>{author.name}</strong>
          </Link>
          | {author.summary}
        </p>
        <div className="flex">
          <p className="text-sm mt-1 mr-3">ថ្ងៃទី៖ {getDateInKhmer(date)}</p>
          <p className="text-sm mt-1">ប្រវែង៖ {readingTime} នាទី</p>
        </div>
      </div>
    </div>
  )
}

export default Writer
